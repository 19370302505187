.about__inner {
    padding: 120px 0;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    font-size: 32px;
    text-transform: uppercase;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
    }
        @include media("<=430px") {
            padding: 80px 0;
        }
}

.about__title {
    max-width: 560px;
    width: 100%;
    font-weight: 800;
    @include media("<=430px") {
        font-size: 24px;
    }
}

.about__text {
    max-width: 480px;
    width: 100%;
    font-weight: 500;
    &-small {
        font-size: 20px;
        text-transform: none;
    }
        @include media("<=810px") {
            max-width: 560px;
        }
        @include media("<=430px") {
            font-size: 18px;
        }
}

.about__image {
    width: 100%;
    display: block;
    img {
        width: 100%;
        display: block;
    }
}
