.info {
    padding: 120px 0;
    background-color: #000;
}

.info__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    @include media("<=810px") {
      flex-direction: column;
    }
}

.info__content {
    max-width: 511px;
    width: 100%;
    line-height: 1.2;
    color: #fff;
    @include media("<=810px") {
        max-width: 560px;
    }
}

.info__image {
    max-width: 480px;
    width: 100%;
    img {
        width: 100%;
        display: block;
    }
        @include media("<=810px") {
            max-width: 560px;
        }
}

.info__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 24px;
    text-transform: uppercase;
    @include media("<=810px") {
        font-size: 30px;
    }
}

.info__text {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    margin-bottom: 40px;
}