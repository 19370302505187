.header {
  width: 100%;
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
    z-index: 10;
    background-color: transparent;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   
  }
  @include media("<=430px") {
    
  }
 &-page {

 }
}

.header__inner {
  
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  z-index: 30;
  column-gap: 8px;
  @include media("<=1080px") {
  
      margin: 0 auto;
    }
  @include media("<=810px") {
   
  }
}

.header__nav {
  display: none;
  @include media("<=1080px") {
    
  }


    &.active {
      display: flex;
      position: absolute;
      height: 97vh;
      width: 100%;
      max-width: none;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-image: url(../images/hero-bcg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 20;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 20px;
      flex-direction: column;
      row-gap: 16px;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      opacity: 1;

      @include media("<=430px") {
        padding-bottom: 0;

      }

      .header__link {
        padding: 15px;

        @include media("<=430px") {
         
        }

        @include media("<=350px") {
          
        }
      }
    }
  
}

.header__link {
  font-size: 32px;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease;
  position: relative;
 text-transform: uppercase;
border-radius: 12px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(10px);
  display: flex;
    align-items: center;
    justify-content: center;
    max-width: 424px;
    text-align: center;
    width: 100%;
  @include media("<=810px") {
     max-width: 300px;
     font-size: 28px;
    
  }

  
    &:hover {
        background: #FDFBA0;
        color: #000;
      }
  
  
}

.header__button {
  border-radius: 12px;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(10px);
    cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      padding: 17px;
      max-width: 424px;
      margin-left: auto;
      width: 100%;
      transition: all ease-in-out 0.3s;
      &:hover {
        background: #FDFBA0;
        color: #000;
      }
            @include media("<=810px") {
              display: none;
            }
}



.logo {
  display: block;
  max-width: 171px;
  
  width: 100%;
  z-index: 21;
  @include media("<=1440px") {
    
  }
    @include media("<=1080px") {
    
    }
  @include media("<=810px") {
   position: relative;
    top: auto;
      left: auto;
      transform: none;
  }
    @include media("<=430px") {
      
    }
  img {
    width: 100%;
    display: block;
  }
  &-footer {
    z-index: 1;
  }
}

