.features {
  position: relative;
  padding: 60px 0;
  overflow: hidden;
  
  @include media("<=1440px") {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
}

.features__inner {

  display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    @include media("<=1440px") {
      

    }
        @include media("<=1080px") {
          gap: 16px;
        }
                @include media("<=810px") {
                   flex-direction: column;
                }
        @include media("<=500px") {
          
        }
}

.features__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 590px;
  height: 239px;
  flex: 0 1 48%;
  padding: 30px;
  text-align: left;
  border-radius: 20px;

    background: #fff;
  transition: all 0.8s ease 0.2s;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
 
  }
  
  @include media("<=810px") {
      height: auto;
  }
  @include media("<=450px") {
    padding: 24px;
  }
 @include media("<=360px") {
   

 }
  
}

 .features__icon {
   max-width: 50px;
   width: 100%;
   margin-bottom: 15px;

   @include media("<=1440px") {
     
   }

   @include media("<=810px") {
     
   }

   @include media("<=380px") {
     max-width: 36px;
   }

   img {
     width: 100%;
     display: block;
   }
 }

.features__title {
  color: #8C32FF;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
@include media("<=1440px") {
  
}
  @include media("<=1280px") {
   
  }

  @include media("<=450px") {
    font-size: 20px;
  }
    @include media("<=380px") {
      
    }
}

.features__text {
  color: #1D1D1D;
    font-size: 15px;
    line-height: 120%;
@include media("<=1080px") {
    font-size: 12px;
  }
  @include media("<=810px") {
    font-size: 14px;

  }

  @include media("<=430px") {
    font-size: 12px;
  }
}

