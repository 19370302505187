.contacts {
 padding: 80px 0 ;
background-image: url(../images/contacts-bcg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media("<=810px") {
        padding: 120px 0;
    }
}

.contacts__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    @include media("<=1200px") {

    }
    @include media("<=900px") {
        
    }
          @include media("<=810px") {
            
          }
}

.contacts__email {
    color: #FFF;
    line-height: 1.2;
    padding-top: 8px;
    p {
        font-weight: 500;
    }
    a {
        color: #FFF;
        text-decoration: none;
    }
}

.contacts__content {
    flex: 0 1 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
        background: #FFF;
        padding: 20px 32px;
        max-width: 480px;
    @include media("<=430px") {
        padding: 20px 15px;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
   
    @include media("<=1200px") {
        padding-top: 0;
    }
    @include media("<=430px") {
        
    }
}

.form__input {
    width: 100%;

    input {
       
        border-radius: 12px;
            background: rgba(0, 0, 0, 0.10);
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 15px 30px;
        border: none;
        font-family: "Montserrat", sans-serif;
        @include media("<=430px") {}
    }
    textarea {
        resize: none;
        width: 100%;
            font-size: 14px;
            color: #000;
           font-weight: 600;
        border-radius: 12px;
            background: rgba(0, 0, 0, 0.10);
            padding: 15px 30px;
    border: none;
        font-family: "Montserrat", sans-serif;
    }
    
}

.location {
    padding: 80px 0;
}

.location__inner {
    display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;
        @include media("<=810px") {
            flex-direction: column;
            gap: 30px;
        }
}

.map {
    max-width: 740px;
    width: 100%;
    img {
        width: 100%;
    }
}

.location__content {
    display: flex;
        flex-direction: column;
        row-gap: 20px;
        font-size: 20px;
            font-weight: 500;
        justify-content: flex-start;
        @include media("<=810px") {
            align-items: center;
            text-align: center;
        }
        a {
            font-size: 20px;
            font-weight: 500;
            color: #000;
            text-decoration: none;
        }
}

