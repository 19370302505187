.gallery {
    padding: 0 0 60px;
}

.gallery__title {
    font-size: 40px;
    font-weight: 800;
}

.gallery__inner {
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    @include media("<=1440px") {
        justify-content: center;
    }
        @include media("<=430px") {
            flex-wrap: wrap;
            row-gap: 24px;
        }
}

.gallery__item {
    max-width: 387px;
    font-size: 24px;
    font-weight: 800;
    @include media("<=1440px") {
       
    }
        @include media("<=430px") {
            
        }
    img {
        width: 100%;
        display: block;
    }
}

.gallery__image {
    width: 100%;
    margin-bottom: 20px;
}