.hero {
 margin: 12px;
 border-radius: 20px;
  position: relative;
//  z-index: 2;
  overflow-x: clip;
  background-image: url(../images/hero-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 74px 0 24px;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
   padding-top: 220px;
    
  }
  @include media("<=430px") {
    padding-top: 180px;
  }
  &-hotels {
    background-image: url(../images/hotels-bcg.png);
  }
  &-contacts {
  background-image: url(../images/contacts-bcg.png);
  }
  &-about {
    background-image: url(../images/about-bcg.png);
  }
  &-page {
    padding: 160px 0 80px;
    background-image: none;
    background-color: #000;
  }
  
}

.hero__inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 20px;
  @include media("<=1080px") {
    margin: 0 auto;
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  @include media("<=430px") {
    
  }
  @include media("<=375px") {
  }
  @include media("<=320px") {
  }
  &-main {
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
      }
  }
}

.hero__content {
  flex: 0 1 100%;
  max-width: 580px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include media("<=1440px") {
    
  }
  @include media("<=1080px") {
    
    
  }
  @include media("<=810px") {
    flex: 0 1 100%;
    margin: 0 auto;
    
  }
    @include media("<=430px") {
      
    }
   
}

.hero__title {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.91), 0px 4px 20px rgba(0, 0, 0, 0.91);
  text-transform: uppercase;
  line-height: 1.2;
  width: 100%;
  margin-top: 36px;
  opacity: 0;
  transition: all 0.8s ease 0.2s;
  
    &._active {
      opacity: 1;
    }
        @include media("<=1440px") {
         
        }
  @include media("<=1280px") {
    font-size: 42px;
  }

  @include media("<=1080px") {
    font-size: 32px;
  }
  @include media("<=810px") {
    max-width: 560px;
  margin-top: 30px;
    font-size: 32px;
  }
  @include media("<=550px") {
  
  }

  @include media("<=410px") {
    font-size: 24px;
  }
  img {
    width: 100%;
    display: block;
   
  }
}

.hero__info {
  max-width: 480px;
  width: 100%;
  @include media("<=810px") {
    max-width: 580px;
  }
}

.hero__block {
  border-radius: 12px;
    background: #FFF;
    min-height: 250px;
    padding:  20px 32px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    @include media("<=430px") {
      padding: 20px 15px;
      min-height: 220px;
    }
}

.hero__block-title {
  display: flex;
  column-gap: 24px;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  flex: 0 1 100%;
  img {
    max-width: 48px;
    width: 100%;
    max-height: 48px;
  }
    @include media("<=430px") {
      font-size: 20px;
    }
}

.hero__text {
  padding-top: 24px;
  border-top: 1px #000 solid;
  font-size: 16px;
  line-height: 1.2;
  @include media("<=430px") {
      font-size: 14px;
    }
}


.hero__item {
  border-radius: 12px;
    background: #FDFBA0;
    width: 100%;
    display: flex;
    padding: 20px 24px;
    gap: 24px;
    
    @include media("<=430px") {
        padding: 20px 15px;
      }
}

.hero__item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.2;
  p {
    font-weight: 600;
  }
  .rate {
    max-width: 106px;
  }
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  padding: 15px;
  max-width: 125px;
 width: 100%;
border-radius: 12px;
  background: #FDFBA0;
  transition: all ease-in-out 0.3s;
  position: relative;
  z-index: 9;
  &:hover {
    background-color: #ffffffdd;
    color: #000;
  }
    @include media("<=1440px") {
      
    }
        @include media("<=810px") {
         
        }
    @include media("<=430px") {
      
    }
        @include media("<=375px") {
          
        }
 &-black {
   background-color: #000;
   color: #FFF;
 }
  &-hotels {
    max-width: 100%;
    
  }  
  &-gallery {
    max-width: 357px;

  }
  &-form {
    margin-top: 15px;
    background-color: #000;
      color: #FFF;
      max-width: 100%;
      border: none;
    @include media("<=810px") {
      max-width: 100%;
    }
  }
}




.main__inner {
  display: flex;
  justify-content: space-between;
  @include media("<=810px") {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }
}

.main__image {
  flex: 0 1 48.5%;
  width: 100%;
  @include media("<=810px") {
    flex: 0 1 100%;
   
  }
  img {
    width: 100%;
    display: block;
  }
}

.main__content {
  flex:  0 1 auto;
  padding: 40px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 60px;
    flex: 0 1 51%;
}

.main__text {
  max-width: 497px;
  font-size: 16px;
  line-height: 1.2;
}

.main__link {
  max-width: 211px;
  width: 100%;
  border-radius: 12px;
    background: #FDFBA0;
    text-decoration: none;
display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  color: #000;
  font-size: 14px;
  position: relative;
  z-index: 3;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  &:hover {
      background: rgba(255, 255, 255, 0.20);
      color: #fff;
  }
   &-black {
    background-color: #000;
    color: #FFF;
    margin: 40px auto 0;
    &:hover {
        background: #FDFBA0;
        color: #000;
      }
   }  
}

.page__item-button {

  align-self: flex-end;
  transition: all 0.3s ease-in-out;

  @include media("<=450px") {
    max-width: 160px;
  }
}

