body {
  margin: 0;
font-family: "Montserrat", sans-serif;
  background: #FCFCFC;
  color: #000;
  position: relative;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1150px;
  padding: 0 15px;
  margin: 0 auto;
  
}

.wrapper {
  position: relative;
}

.section__title {
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    @include media("<=1200px") {
      font-size: 38px;
    }
    @include media("<=810px") {
      font-size: 40px;
    }
        @include media("<=430px") {
          font-size: 24px;
        }
}

.section__subtitle {
  padding: 20px 0 60px;
  text-align: center;
  line-height: 1.2;
}



.wrapper {
  position: relative;
}

//BURGER BUTTON//
.burger__button {
  display: flex;
  max-width: 48px;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(10px);
    gap: 6px;
    position: relative;
    z-index: 23;
  

  .line {
    height: 2px;
    width: 20px;
    
    background-color: #fff;
    transition: all 200ms ease-in-out;
    opacity: 1;

    
  }

  &.active {
    .line {
      background-color: #fff;

      &.one {
        transform: rotate(45deg) translateY(5px) translateX(5px);
      }

      &.two {
        transform: rotate(-45deg)  translateY(-1px) translateX(0px);

        @include media("<=736px") {
          
        }
      }

      &.three {
        opacity: 0;
      }
    }
  }
}

.page__info {
  padding: 120px 0 60px;
  @include media("<=450px") {
    padding: 40px 0;
  }
}


.arrow {
  margin: 0 auto;
  display: block;
}

.page__item {
  width: 100%;
  max-width: 360px;
  position: relative;
  flex: 0 1 32%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

    justify-content: space-between;
  
     @include media("<=810px") {
       max-width: 460px;
     }
}

.page__content {
  max-width: 540px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  &-right {
    align-items: flex-end;
    text-align: right;
    @include media("<=810px") {
        text-align: left;
        align-items: flex-start;
      }
  }
}

.page__item-title {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: 600;
  text-transform: uppercase;
  @include media("<=1080px") {
   font-size: 18px;
  }
     @include media("<=410px") {
     
     }
}

.page__item-text {
  font-weight: 300;
  font-size: 14px;
  flex: 0 1 100%;
  margin-bottom: 20px;
}



.page__item-button:hover .arrow {
  transform: translate(8px, -5px);
}



.page__image {
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
}

.page__inner {
  padding: 80px 0;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  @include media("<=810px") {
    padding: 60px 0;
  }
}

.page__title {
  
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  transition: all 0.8s ease 0.2s;

  @include media("<=1440px") {}

  @include media("<=1280px") {}

  @include media("<=1080px") {}

  @include media("<=810px") {}

  @include media("<=550px") {

  }

  @include media("<=410px") {
    font-size: 18px;
  }

 
}

.page__text {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  font-size: 16px;
  font-weight: 400;
}

.page {
  padding-top: 40px;
  width: 100%;
  background: #fff;
  @include media("<=810px") {
    
  }
  ul {
    list-style: disc;
    padding: 0 20px;
  }
}