.hotels {
    padding: 120px 0;
    @include media("<=810px") {
        padding-bottom: 40px;
    }
}

.hotels__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 24px;
    position: relative;
    width: 100%;
    @include media("<=630px") {
        flex-direction: column;
    }
}

.hotels__title {
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.91), 0px 4px 20px rgba(0, 0, 0, 0.91);
    width: 100%;
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 16px;
    text-transform: uppercase;
    @include media("<=810px") {
        font-size: 28px;
    }
                 @include media("<=450px") {
                    
                 }
}

.hotels__item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex: 0 1 48.8%;
    aspect-ratio: 1 / 1;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 40px;
    @include media("<=810px") {
        padding: 20px;
    }
    @include media("<=550px") {
        
    }
    &-reverse {
        justify-content: flex-end;
        @include media("<=550px") {
           padding-top: 60vw;
        }
          .about__title {
            color: #fff;
          }
    }
}

.hotels__content {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
 position: relative;
 z-index: 3;

 color: #fff;
}


.hotels__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FCFCFC;
    font-size: 16px;
    font-weight: 300;
    
    line-height: 1.1;
}

.hotels__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
        width: 100%;
        display: block;
        
    }
}