.footer {
border-radius: 20px;
    background: #FDFBA0;
    margin: 12px;
    padding: 20px 0;
    @include media("<=430px") {
padding: 30px 0;
    }
}

.footer__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    
    position: relative;
    @include media("<=810px") {
        flex-direction: column;
        
    }
}

.footer__top {
    width: 100%;
    padding-bottom: 16px;
    display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px #000 solid;
        @include media("<=810px") {
                flex-direction: column;
        gap: 20px;
            }
}


.footer__bottom {
    width: 100%;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media("<=810px") {
            flex-direction: column;
    gap: 20px;
        }
}





.footer__links {
    display: flex;
    row-gap: 24px;
    justify-content: flex-start;
    column-gap: 20px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
        text-align: center;
        row-gap: 20px;
        order: 1;
    }
}

.footer__link {
    color: #000;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
        text-decoration: underline;
    }
}

.footer__social {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    @include media("<=810px") {
        justify-content: center;
        order: 2;
    }

}

.copyright {
    
    text-align: left;
    color: #00000089;
    @include media("<=810px") {
        text-align: center;
        order: 3;
    }
}



